import axios from 'axios';

class API {
  constructor() {
    this.baseURL = 'https://apilbs.luiss.it/wp-json';
    //this.baseURL = 'https://test-apilbs.luiss.it/wp-json';
    this.token = localStorage.getItem('auth-token');
    this.headers = (this.token !== null)
        ? {
            'Accept': 'application/json',
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json'      
          }
        : {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          };
    
    this.config = {
      url: null,
      method: null,
      baseURL: this.baseURL,
      headers: this.headers,
      params: {},
      data: {},
      timeout: 30000, // default is `0` (no timeout)
    };

    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (401 === error.response.status) {
        window.location = '/login';
        localStorage.removeItem('auth-token');
        console.log('interceptor this.token: ',this.token);
        this.setToken(null);
      } else {
        return Promise.reject(error);
      }
    });
  }

  setToken = (token) => {
    this.token = token;
    if(token !== null) {
      this.headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json'
      }
    } else {
      this.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
    this.config.headers = this.headers;
  }

  get = (url, params) => {
    this.config.url = url;
    this.config.method = 'get';
    this.config.params = params;
    this.config.data = {}; 
    return axios.request(this.config);
  };

  post = (url, data) => {
    this.config.url = url;
    this.config.method = 'post';
    this.config.params = {};
    this.config.data = data;    
    return axios.request(this.config);
  };

  put = (url, data) => {
    this.config.url = url;
    this.config.method = 'put';
    this.config.params = {};
    this.config.data = data;
    return axios.request(this.config);
  };
}

export default new API();

